
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import DTCls from "@/utils/datetime";
import { saveAs } from "@/api/request/base";
import Pagination from "@/comp/Pagination/index.vue";
import { getPromoteUserAwardList, exportPromoteUserAward } from "@/api/request/promotion";

//组件
@Component({
  name: "UserPromotionRecord",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = []; //数据
  private total: number = 0; //总数
  private listLoading: boolean = true; // 列表加载

  //请求数据
  private listQuery: any = {
    //页码
    row: 20,
    page: 1,

    //零食数据
    time: [], //时间
    user_id_str: "", //用户ID
    from_user_id_str: "", //上级ID

    //数据
    end_time: "", //结束时间
    start_time: "", //开始时间
    award_type: 0, //奖励类型
    award_status: 0, //奖励状态
    user_id: undefined, //用户ID
    from_user_id: undefined, //上级ID
  };

  //时间参数
  get pickerOptions(): any {
    return {
      shortcuts: [
        {
          text: "今日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getTodayStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "昨日",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getYesterdayStartTime()), new Date(DTCls.getYesterdayEndTime())]);
          },
        },
        {
          text: "本周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurWeekStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上周",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastWeekStartTime()), new Date(DTCls.getLastWeekEdnTime())]);
          },
        },
        {
          text: "本月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getCurMonthStartTime()), new Date(DTCls.getTodayEndTime())]);
          },
        },
        {
          text: "上月",
          onClick(picker: any) {
            picker.$emit("pick", [new Date(DTCls.getLastMonthStartTime()), new Date(DTCls.getLastMonthEndTime())]);
          },
        },
        {
          text: "最近一周",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker: any) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "全部",
          onClick(picker: any) {
            picker.$emit("pick", []);
          },
        },
      ],
      disabledDate(time: Date) {
        return time.getTime() > Date.now();
      },
    };
  }

  //奖励类型
  private awardTypeList: any[] = [
    { type: 0, name: "全部" },
    { type: 1, name: "拉新奖励" },
    { type: 2, name: "首充奖励" },
    { type: 3, name: "累计充值20" },
    { type: 4, name: "累计充值100" },
    { type: 5, name: "团队累计充值60" },
    { type: 6, name: "团队累计充值300" },
  ];

  //奖励状态
  private awardStatusList: any[] = [
    { status: 0, name: "未领取" },
    { status: 1, name: "已领取" },
    { status: 2, name: "不可领取" },
  ];

  //创建调用
  created() {
    //获取列表
    this.getList();
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //注册时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //其他数据
    this.listQuery.user_id = Number(this.listQuery.user_id_Str) > 0 ? Number(this.listQuery.user_id_Str) : undefined;
    this.listQuery.from_user_id = Number(this.listQuery.from_user_id_str) > 0 ? Number(this.listQuery.from_user_id_str) : undefined;

    //获取数据
    const { data } = await getPromoteUserAwardList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //导出Excel
  private async handleExcel() {
    //显示等待
    this.listLoading = true;

    //注册时间赋值
    if (this.listQuery.time && this.listQuery.time.length == 2) {
      this.listQuery.end_time = `${this.listQuery.time[1]} 23:59:59`;
      this.listQuery.start_time = `${this.listQuery.time[0]} 00:00:00`;
    } else {
      this.listQuery.end_time = "";
      this.listQuery.start_time = "";
    }

    //其他数据
    this.listQuery.user_id = Number(this.listQuery.user_id_Str) > 0 ? Number(this.listQuery.user_id_Str) : undefined;
    this.listQuery.from_user_id = Number(this.listQuery.from_user_id_str) > 0 ? Number(this.listQuery.from_user_id_str) : undefined;

    //获取数据
    const { data } = await exportPromoteUserAward(this.listQuery);

    //保存数据
    saveAs(data, "用户推广奖励记录");

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleSearch(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }
}
